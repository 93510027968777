import { z } from "zod";

import { type ThumbnailFormatEnum } from "@projectluna/codegen/schema";
import { appConfigSchema } from "@projectluna/lib/config/schema";
import { prepareConfig } from "@projectluna/lib/zod/env";

import type { PaymentMethodType } from "@/lib/payment/types";

import packageJson from "../../package.json";
import { envBool, envToStrList } from "./helpers";

const clientConfigSchema = z
  .object({
    // stripe.com/docs/api/payment_intents/object#payment_intent_object-payment_method_types
    AVAILABLE_PAYMENT_METHODS: z
      .array(z.string())
      .default([
        "card",
        "blik",
        "p24",
        "paypal",
        "link",
      ] as PaymentMethodType[]),

    AVAILABILITY_INFO_QUANTITY: z.coerce.number().default(1),

    GA_MEASUREMENT_ID: z.string(),
    GA_PROPERTY_ID: z.string(),
    GTM_ID: z.string().optional(),

    INPOST_ENVIRONMENT: z.enum(["DEV", "PROD"]),
    INPOST_GEOWIDGET_TOKEN: z.string(),

    STRIPE_PUBLISHABLE_API_KEY: z.string(),

    INSTAGRAM_FEED_SIZE: z.coerce.number().default(16),

    IMAGES_CUSTOM_LOADER: envBool.default("false"),
  })
  .and(appConfigSchema);

const constConfig = {
  PAYMENT_ELEMENT_ID: "payment",

  STRIPE_GATEWAY_ID: "saleor.payments.stripe",

  IMAGES_QUALITY: 90,
  IMAGES_FORMAT: "AVIF" as ThumbnailFormatEnum,
  IMAGES_SIZE: {
    CATEGORY: 2024,
    COLLECTION: 2024,
    PRODUCT_LISTING: 1024,
    PRODUCT_DETAIL_PAGE: 1024,
    CAROUSEL: 1024,
    OPEN_GRAPH: 1024,
    PRODUCT_CARD: 1024,
    PRODUCT_GRID: 1024,
    FEATURED_COLLECTION: 2024,
    LINE: 256,
  },

  PAGINATION_SIZE: {
    PRODUCT_LISTING: 12,
    PRODUCT_DETAIL_PAGE_CATEGORY_CAROUSEL: 10,
    HOME_NEWEST_PRODUCTS: 8,
    HOME_POPULAR_PRODUCTS: 8,
    HOME_TOP_SELLING_VARIANTS: 10,
    POPULAR_PRODUCTS: 8,
    SEARCH: 6,
  },

  LISTING_DEFAULT_SORT: {
    field: "CREATED_AT",
    direction: "DESC",
  },

  NEW_PRODUCT_BADGE_DAYS: 7,

  COOKIE_KEY: {
    CHECKOUT_ID: "checkoutId",
    COOKIE_CONSENT: "cookieConsent",
  },

  MAINTENANCE_PAGE_PATH: "/maintenance",
  BASIC_AUTH_PATH: "/api/auth/basic",

  DEBOUNCE_MS: 500,
  SEARCH_MIN_CHARS: 3,

  MENU_ITEMS_SLUG: ["footer-first", "footer-second", "navbar"],

  PROMO_BANNER_PAGE_TYPE_SLUG: "promotion-bar",

  HEADER_HEIGHT: 64,
} as const;

const parsedClientConfig = prepareConfig({
  name: "CLIENT_CONFIG",
  schema: clientConfigSchema,
  input: {
    AVAILABILITY_INFO_QUANTITY:
      process.env.NEXT_PUBLIC_AVAILABILITY_INFO_QUANTITY,

    GA_MEASUREMENT_ID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID,
    GA_PROPERTY_ID: process.env.NEXT_PUBLIC_GA_PROPERTY_ID,
    GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,

    INPOST_ENVIRONMENT: process.env.NEXT_PUBLIC_INPOST_ENVIRONMENT,
    INPOST_GEOWIDGET_TOKEN: process.env.NEXT_PUBLIC_INPOST_GEOWIDGET_TOKEN,

    STRIPE_PUBLISHABLE_API_KEY:
      process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_API_KEY,

    INSTAGRAM_FEED_SIZE: process.env.NEXT_PUBLIC_INSTAGRAM_FEED_SIZE,

    VERSION: packageJson.version,
    NAME: packageJson.name,

    AVAILABLE_PAYMENT_METHODS: envToStrList(
      process.env.NEXT_PUBLIC_AVAILABLE_PAYMENT_METHODS,
      true
    ),

    IMAGES_CUSTOM_LOADER: process.env.NEXT_PUBLIC_IMAGES_CUSTOM_LOADER,
  },
});

export const CLIENT_CONFIG = {
  ...parsedClientConfig,
  ...constConfig,
};
