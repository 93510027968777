import { z } from "zod";

import { saleorConfigSchema } from "#saleor/config/schema";
import { prepareConfig } from "#zod/env";

const configSchema = z
  .object({
    STOREFRONT_URL: z.string().url(),
    ENVIRONMENT: z
      .enum(["development", "local", "test", "production"])
      .default("local"),
    FETCH_TIMEOUT: z
      .number()
      .default(10000)
      .describe("Fetch timeout in milliseconds."),
  })
  .and(saleorConfigSchema);

const constConfig = {
  STORE_NAME: "Project Luna",
  STORE_EMAIL: "sklep.projectluna@gmail.com",
  STORE_PHONE: "+48733454223",

  SOCIAL_LINKS: {
    FACEBOOK: "https://www.facebook.com/profile.php?id=100063686375630",
    INSTAGRAM: "https://www.instagram.com/_project_luna_/",
    TIK_TOK: "https://www.tiktok.com/@_project_luna_",
    PINTEREST: "https://pl.pinterest.com/Project_luna/",
  },

  MAINTENANCE_PAGE_PATH: "/maintenance",
  BASIC_AUTH_PATH: "/api/auth/basic",

  IS_SSR: typeof window === "undefined",
  IS_BROWSER: typeof window !== "undefined",

  IS_DEVELOPMENT: process.env.NODE_ENV === "development",
};

const parsedConfig = prepareConfig({
  schema: configSchema,
  input: {
    STOREFRONT_URL: process.env.NEXT_PUBLIC_STOREFRONT_URL,
    SALEOR_URL: process.env.NEXT_PUBLIC_SALEOR_URL,
    FETCH_TIMEOUT: process.env.NEXT_PUBLIC_FETCH_TIMEOUT,
    ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  },
  name: "COMMON_CLIENT_CONFIG",
});

export const COMMON_CLIENT_CONFIG = {
  ...parsedConfig,
  ...constConfig,
};
